import "./App.css";
import Confetti from "./Confetti";

const App = () => {
  return (
    <div className="App">
      <Confetti />
      <header className="App-header">
        <img className="logo" src="logo-white.png" alt="tomcode.io computer logo"></img>
        <h1 style={{ marginBottom: "0px" }}>tomcode<span className="blue">.io</span></h1>
        <p style={{ marginTop: "10px", marginBottom: "50px" }}>
          Love the web 💻
        </p>
      </header>
    </div>
  );
};

export default App;
